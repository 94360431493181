import Header from "../components/Header/Header";

const Layout = ({ children, flex = true }) => {
  return (
    <div className={`${flex ? "content-main" : ""} position-relative`}>
      <Header />
      {children}
    </div>
  );
};

export default Layout;
