import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import filterSlice from "./features/filter/filterSlice";
import dailyChallengeSlice from "./features/dailyChallenge/dailyChallengeSlice";
import customChallengeSlice from "./features/customChallenge/customChallengeSlice";
import modalSlice from "./features/modal/modalSlice";
import gameSlice from "./features/game/gameSlice";
import quickPlaySlice from "./features/quickPlay/quickPlaySlice";
import leaderSlice from "./features/leader/leaderSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    quickPlay: quickPlaySlice,
    dailyChallenge: dailyChallengeSlice,
    customChallenge: customChallengeSlice,
    filters: filterSlice,
    modal: modalSlice,
    game: gameSlice,
    leader: leaderSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
