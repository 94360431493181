import { Suspense } from "react";
import MyRoutes from "./routers";
import FallBack from "./components/FallBack/FallBack";
import withAuthCheck from "./auth/withAuthCheck";

function App() {
  const MainComponentWithAuthCheck = withAuthCheck(MyRoutes);
  return (
    <Suspense fallback={<FallBack />}>
      {/* <MyRoutes /> */}
      <MainComponentWithAuthCheck />
    </Suspense>
  );
}

export default App;
