import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  yearFilterBegin: null,
  yearFilterEnd: null,
  sportFilter: null,
  brandFilter: null,
  playerFilter: null,
  roundSelect: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setBrandFilter: (state, action) => {
      state.brandFilter = action.payload;
    },
    setPlayerFilter: (state, action) => {
      state.playerFilter = action.payload;
    },
    setSportFilter: (state, action) => {
      state.sportFilter = action.payload;
    },
    setYearFilterBegin: (state, action) => {
      state.yearFilterBegin = action.payload;
    },
    setYearFilterEnd: (state, action) => {
      state.yearFilterEnd = action.payload;
    },
    setRoundSelect: (state, action) => {
      state.roundSelect = action.payload;
    },
  },
});

export const {
  setBrandFilter,
  setPlayerFilter,
  setSportFilter,
  setYearFilterBegin,
  setYearFilterEnd,
  setRoundSelect,
} = filterSlice.actions;
export default filterSlice.reducer;
