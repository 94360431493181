import { createSlice } from "@reduxjs/toolkit";
import { storage } from "../../../config/firebase-config";
import masterDataJson from "../../../data/masterData.json";
// import { ref, get } from "firebase/database";
import { getDownloadURL, ref as storeRef } from "firebase/storage";

// Define the initial state using that type
const initialState = {
  isQuickPlay: false,
  quickPlayData: [],
  loading: false,
  error: null,
};

export const quickPlaySlice = createSlice({
  name: "quickPlay",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsQuickPlay: (state, action) => {
      state.isQuickPlay = action.payload;
    },
    setQuickPlayData: (state, action) => {
      state.quickPlayData = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setIsQuickPlay, setQuickPlayData, setLoading, setError } =
  quickPlaySlice.actions;

// Asynchronous action to listen for authentication state changes
export const listenToQuickPlay = () => async (dispatch) => {
  const gradesDataG = masterDataJson?.Master;
  const filteredData = gradesDataG.filter(
    (item) =>
      item["Total population"] > 1 &&
      item["Population higher"] > 1 &&
      item["Grade"] !== "N0: AUTHENTIC" &&
      item["Grade"] !== "AA: AUTHENTIC ALTERED" &&
      item["Set"] === 1
  );
  const shuffledData = filteredData?.sort(() => Math.random() - 0.5);
  const gradesData = shuffledData.slice(0, 5);
  try {
    dispatch(setLoading(true));

    const getData = async () => {
      try {
        if (gradesData) {
          const filteredGradeDataPromises = Object.values(gradesData).map(
            async (grade) => {
              // Create a new object with only the desired properties
              const filteredGradeData = {};
              if (grade["Back path"]) {
                // const imageRef = storeRef(storage, gradeData["Back path"]);
                // const imageRef = storeRef(storage, "Master_images/nofund.png");
                // const url = await getDownloadURL(imageRef);
                // filteredGradeData["backPath"] = url;
                try {
                  const decodedPath = decodeURIComponent(grade["Back path"]);
                  const imageRef = storeRef(storage, decodedPath);
                  const url = await getDownloadURL(imageRef);
                  filteredGradeData["backPath"] = url;
                } catch (error) {
                  if (
                    error.code === "storage/object-not-found" ||
                    error.code === "storage/unauthorized"
                  ) {
                    const url = "https://i.ibb.co/vvNFKxy/nofund.png";
                    filteredGradeData["backPath"] = url;
                  }
                }
              }
              if (grade["Front path"]) {
                const decodedPath = decodeURIComponent(grade["Front path"]);
                // const imageRef = storeRef(storage, gradeData["Front path"]);
                // const imageRef = storeRef(storage, "Master_images/nofund.png");
                // const url = await getDownloadURL(imageRef);
                // filteredGradeData["frontPath"] = url;
                try {
                  const imageRef = storeRef(storage, decodedPath);
                  const url = await getDownloadURL(imageRef);
                  filteredGradeData["frontPath"] = url;
                } catch (error) {
                  if (
                    error.code === "storage/object-not-found" ||
                    error.code === "storage/unauthorized"
                  ) {
                    const url = "https://i.ibb.co/vvNFKxy/nofund.png";
                    filteredGradeData["frontPath"] = url;
                  }
                }
              }

              if (grade["Brand"]) {
                filteredGradeData["Brand"] = grade["Brand"];
              }
              if (grade["Cert number"]) {
                filteredGradeData["certNumber"] = grade["Cert number"];
              }
              if (grade["Grade"]) {
                filteredGradeData["grade"] = grade["Grade"];
              }
              if (grade["Players"]) {
                filteredGradeData["player"] = grade["Players"];
              }
              if (grade["Variety"]) {
                filteredGradeData["variety"] = grade["Variety"];
              }
              if (grade["Year"]) {
                filteredGradeData["year"] = grade["Year"];
              }
              return filteredGradeData;
            }
          );

          const filteredGradeData = await Promise.all(
            filteredGradeDataPromises
          );
          dispatch(setQuickPlayData(await filteredGradeData));
        } else {
          console.log("Grades data is null.");
        }

        dispatch(setIsQuickPlay(gradesData.length > 0 ? true : false));
        dispatch(setLoading(false));
      } catch (e) {
        console.log("Error:", e);
        dispatch(setLoading(false));
      }
    };

    getData();
  } catch (error) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};

export default quickPlaySlice.reducer;
