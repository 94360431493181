// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA6a9iKOdCqpqaSa8r0Mg6AO3jScQ50JiY",
  authDomain: "graides-78398.firebaseapp.com",
  databaseURL: "https://graides-78398-default-rtdb.firebaseio.com",
  projectId: "graides-78398",
  storageBucket: "graides-78398.appspot.com",
  messagingSenderId: "396433114521",
  appId: "1:396433114521:web:3f2a73c0d52d2f8fe87f08",
  measurementId: "G-RV3MCLLPKV",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
