import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../../../config/firebase-config";
// Define the initial state using that type
const initialState = {
  isLoggedIn: false,
  loading: false,
  error: null,
  userData: {},
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setIsLoggedIn, setUserData, setLoading, setError } =
  userSlice.actions;

// Asynchronous action to listen for authentication state changes
export const listenToAuthChanges = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setIsLoggedIn(false));
    auth.onAuthStateChanged((user) => {
      if (user) {
        const { displayName, email, photoURL, accessToken, uid } = user;
        localStorage.setItem(
          "userData",
          JSON.stringify({
            displayName,
            email,
            photoURL,
            accessToken,
            uid,
            isLoggedIn: true,
          })
        );
        localStorage.setItem("userLogin", true);
        dispatch(
          setUserData({ displayName, email, photoURL, accessToken, uid })
        );

        dispatch(setIsLoggedIn(true));
      } else {
        dispatch(setUserData(null));
        localStorage.setItem("userData", null);

        dispatch(setIsLoggedIn(false));
        localStorage.setItem("userLogin", false);
      }
      dispatch(setLoading(false));
    });
  } catch (error) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};

export default userSlice.reducer;
