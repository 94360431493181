import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDaily: false,
  dailyBoardData: [],
  leaderBoardData: [],
  loading: false,
  error: null,
};

const leaderSlice = createSlice({
  name: "leader",
  initialState,
  reducers: {
    setIsDaily: (state, action) => {
      state.isDaily = action.payload;
    },
    setDailyBoardData: (state, action) => {
      state.dailyBoardData = action.payload;
    },
    setLeaderBoardData: (state, action) => {
      state.leaderBoardData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setIsDaily,
  setDailyBoardData,
  setLeaderBoardData,
  setLoading,
  setError,
} = leaderSlice.actions;

export default leaderSlice.reducer;
