import { createSlice } from "@reduxjs/toolkit";
import { db, storage } from "../../../config/firebase-config";
import masterDataJson from "../../../data/masterData.json";
import { ref, get } from "firebase/database";
import { getDownloadURL, ref as storeRef } from "firebase/storage";

// Define the initial state using that type
const initialState = {
  isDailyChallenge: false,
  todayRounds: {},
  selectSliderData: {},
  roundsData: [],
  completeRoundsData: [],
  completeRoundsQuickPlayData: [],
  completeRoundsDailyPlayData: [],
  completeRoundsCustomGameData: [],
  isUserCompleteRounds: false,
  loading: false,
  error: null,
};

export const dailyChallengeSlice = createSlice({
  name: "DailyChallenge",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsDailyChallenge: (state, action) => {
      state.isDailyChallenge = action.payload;
    },
    setTodayRounds: (state, action) => {
      state.todayRounds = action.payload;
    },
    setSelectSliderData: (state, action) => {
      state.selectSliderData = action.payload;
    },
    setRoundsData: (state, action) => {
      state.roundsData = action.payload;
    },

    setCompleteRoundsData: (state, action) => {
      state.completeRoundsData = action.payload;
    },
    setCompleteRoundsQuickPlayData: (state, action) => {
      state.completeRoundsQuickPlayData = action.payload;
    },
    setCompleteRoundsDailyPlayData: (state, action) => {
      state.completeRoundsDailyPlayData = action.payload;
    },
    setCompleteRoundsCustomGameData: (state, action) => {
      state.completeRoundsCustomGameData = action.payload;
    },
    setIsUserCompleteRounds: (state, action) => {
      state.isUserCompleteRounds = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setIsDailyChallenge,
  setTodayRounds,
  setSelectSliderData,
  setRoundsData,
  setCompleteRoundsData,
  setCompleteRoundsQuickPlayData,
  setCompleteRoundsDailyPlayData,
  setCompleteRoundsCustomGameData,
  setIsUserCompleteRounds,
  setLoading,
  setError,
} = dailyChallengeSlice.actions;

// Asynchronous action to listen for authentication state changes
export const listenToDailyChallenge = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const getData = async () => {
      const userRef = ref(db, "DailyChallenge");
      // const gradesRef = ref(db, "GraidesMasterDatabase");
      // const roundsRef = ref(db, "Rounds");
      try {
        const snap = await get(userRef);
        const data = snap.val();
        const currentDate = new Date().toISOString().split("T")[0];
        const filteredData = await data.filter((item) => {
          const parts = item.Date.split("/");
          const formattedDate =
            parts[2] +
            "-" +
            parts[0].padStart(2, "0") +
            "-" +
            parts[1].padStart(2, "0");

          return formattedDate === currentDate;
        });
        // const gradesSnap = await get(gradesRef);
        // const gradesData = await gradesSnap.val();
        const gradesData = await masterDataJson?.Master;

        // const snapRound = await get(roundsRef);
        // const roundData = snapRound.val();
        // const filterRoundByUser = await roundData?.filter((item) => {
        //   return item.UserID === userData.uid;
        // });
        // dispatch(setCompleteRoundsData(filterRoundByUser));

        if (gradesData) {
          const roundNumbers = Object?.keys(filteredData[0]).filter((key) =>
            key.startsWith("Round")
          );
          // Iterate over each round number
          const filteredGradeDataPromises = await roundNumbers.map(
            async (roundNumber) => {
              // Get the certificate number for this round
              const certNumber = await filteredData[0][roundNumber];
              // Find the corresponding grade data for this certificate number
              let gradeData = await gradesData.find(
                (grade) => grade["Cert number"] === certNumber
              );
              // Create a new object with only the desired properties
              const filteredGradeData = {};
              if (gradeData) {
                if (gradeData["Back path"]) {
                  // const imageRef = storeRef(storage, gradeData["Back path"]);
                  // const imageRef = storeRef(storage, "Master_images/nofund.png");
                  // const url = await getDownloadURL(imageRef);
                  // filteredGradeData["backPath"] = url;
                  try {
                    const decodedPath = decodeURIComponent(
                      gradeData["Back path"]
                    );
                    const imageRef = storeRef(storage, decodedPath);
                    const url = await getDownloadURL(imageRef);
                    filteredGradeData["backPath"] = url;
                  } catch (error) {
                    if (
                      error.code === "storage/object-not-found" ||
                      error.code === "storage/unauthorized"
                    ) {
                      const url = "https://i.ibb.co/vvNFKxy/nofund.png";
                      filteredGradeData["backPath"] = url;
                    }
                  }
                }
                if (gradeData["Front path"]) {
                  const decodedPath = decodeURIComponent(
                    gradeData["Front path"]
                  );
                  // const imageRef = storeRef(storage, gradeData["Front path"]);
                  // const imageRef = storeRef(storage, "Master_images/nofund.png");
                  // const url = await getDownloadURL(imageRef);
                  // filteredGradeData["frontPath"] = url;
                  try {
                    const imageRef = storeRef(storage, decodedPath);
                    const url = await getDownloadURL(imageRef);
                    filteredGradeData["frontPath"] = url;
                  } catch (error) {
                    if (
                      error.code === "storage/object-not-found" ||
                      error.code === "storage/unauthorized"
                    ) {
                      const url = "https://i.ibb.co/vvNFKxy/nofund.png";
                      filteredGradeData["frontPath"] = url;
                    }
                  }
                }
                if (gradeData["Brand"]) {
                  filteredGradeData["Brand"] = gradeData["Brand"];
                }
                if (gradeData["Cert number"]) {
                  filteredGradeData["certNumber"] = gradeData["Cert number"];
                }
                if (gradeData["Grade"]) {
                  filteredGradeData["grade"] = gradeData["Grade"];
                }
                if (gradeData["Players"]) {
                  filteredGradeData["player"] = gradeData["Players"];
                }
                if (gradeData["Variety"]) {
                  filteredGradeData["variety"] = gradeData["Variety"];
                }
                if (gradeData["Year"]) {
                  filteredGradeData["year"] = gradeData["Year"];
                }
              }

              return filteredGradeData;
            }
          );
          // Wait for all promises to resolve
          const filteredGradeData = await Promise.all(
            filteredGradeDataPromises
          );

          dispatch(setRoundsData(await filteredGradeData));
        } else {
          console.log("Grades data is null.");
        }

        dispatch(setTodayRounds(filteredData[0]));
        dispatch(setIsDailyChallenge(filteredData.length > 0 ? true : false));

        dispatch(setLoading(false));
      } catch (e) {
        console.log("Error:", e);
        dispatch(setLoading(false));
      }
    };

    getData();
  } catch (error) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};

export default dailyChallengeSlice.reducer;
