// withAuthCheck.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenToAuthChanges } from "../state/features/user/userSlice";
import { listenToDailyChallenge } from "../state/features/dailyChallenge/dailyChallengeSlice";
import { listenToQuickPlay } from "../state/features/quickPlay/quickPlaySlice";
import { listenGamePlay } from "../state/features/game/gameSlice";
import FallBack from "../components/FallBack/FallBack";

const withAuthCheck = (WrappedComponent) => {
  const AuthCheck = () => {
    const dispatch = useDispatch();
    const { loading, isLoggedIn, userData } = useSelector(
      (state) => state.user
    );
    const { winShow } = useSelector((state) => state.modal);

    useEffect(() => {
      dispatch(listenToQuickPlay());
    }, [dispatch]);

    useEffect(() => {
      dispatch(listenToDailyChallenge());
    }, [dispatch]);

    useEffect(() => {
      dispatch(listenGamePlay({ userData }));
    }, [dispatch, userData, winShow]);

    useEffect(() => {
      dispatch(listenToAuthChanges());
    }, [dispatch]);

    if (loading) {
      return <FallBack />;
    }

    return isLoggedIn ? <WrappedComponent /> : <WrappedComponent />;
  };

  return AuthCheck;
};

export default withAuthCheck;
