import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  winShow: false,
  exitModal: false,
};

const filterSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setWinShow: (state, action) => {
      state.winShow = action.payload;
    },
    setExitModal: (state, action) => {
      state.exitModal = action.payload;
    },
  },
});

export const { setWinShow, setExitModal } = filterSlice.actions;
export default filterSlice.reducer;
