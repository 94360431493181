import React from "react";

import "./FallBack.scss";
const FallBack = () => {
  return (
    <div className="fallback-content">
      <div className="double">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  );
};

export default FallBack;
