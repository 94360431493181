import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  isCustomChallenge: false,
  customRoundsData: [],
  loading: false,
  error: "",
};

export const customChallengeSlice = createSlice({
  name: "CustomChallenge",
  initialState,
  reducers: {
    setIsCustomChallenge: (state, action) => {
      state.isCustomChallenge = action.payload;
    },
    setCustomRoundsData: (state, action) => {
      state.customRoundsData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setIsCustomChallenge,
  setCustomRoundsData,
  setLoading,
  setError,
} = customChallengeSlice.actions;

export default customChallengeSlice.reducer;
