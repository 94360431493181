import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setLeaderBoardData,
  setDailyBoardData,
  setLoading,
} from "../state/features/leader/leaderSlice";
// import { saveData, fetchData } from "../utils/localDB";

import { db } from "../config/firebase-config";
import { ref, get } from "firebase/database";

import {
  setCompleteRoundsData,
  setIsUserCompleteRounds,
  setCompleteRoundsQuickPlayData,
  setCompleteRoundsDailyPlayData,
  setCompleteRoundsCustomGameData,
  // setFullData,
} from "../state/features/dailyChallenge/dailyChallengeSlice";

//layouts
import Layout from "src/containers/Layout";

//pages
const Login = lazy(() => import("../pages/Login/Login"));
const Register = lazy(() => import("../pages/Register/Register"));
const ForgotPassword = lazy(() =>
  import("../pages/ForgotPassword/ForgotPassword")
);
const ConfirmPassword = lazy(() =>
  import("../pages/ConfirmPassword/ConfirmPassword")
);

const Home = lazy(() => import("../pages/Home/Home"));

const PlayScreen = lazy(() => import("../pages/PlayScreen/PlayScreen"));
// const Game = lazy(() => import("../pages/Game/Game"));
const QuickPlay = lazy(() => import("../pages/QuickPlay/QuickPlay"));
const DailyChallenge = lazy(() =>
  import("../pages/DailyChallenge/DailyChallenge")
);

const CustomPlay = lazy(() => import("../pages/CustomPlay/CustomPlay"));

const MyRoutes = () => {
  const { userData } = useSelector((state) => state.user);
  const { todayRounds } = useSelector((state) => state.dailyChallenge);
  const { winShow } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roundsRef = ref(db, "Rounds");
        const snap = await get(roundsRef);
        const data = snap.val();

        if (!data) {
          dispatch(setIsUserCompleteRounds(true));
          // console.error("❌ No data found in the 'Rounds' collection.");
          return;
        }

        // Parse todayRounds.Date into a Date object
        const currentDate = new Date(todayRounds.Date);
        const currentDateString = currentDate.toISOString().split("T")[0];

        const filterRoundByUser = data.filter((item) => {
          try {
            // Attempt to parse DatetimeStarted into a Date object
            const itemDate = new Date(item.DatetimeStarted);
            if (isNaN(itemDate.getTime())) {
              // console.error("❌ Invalid date for item:", item);
              dispatch(setIsUserCompleteRounds(false));
              return false; // Exclude the item from the filtered results
            }
            const itemDateString = itemDate.toISOString().split("T")[0];
            return (
              item.UserID === userData.uid &&
              itemDateString === currentDateString
            );
          } catch (error) {
            // console.error("❌ Error parsing DatetimeStarted:", error);
            // console.error("❌ Invalid item:", item);
            dispatch(setIsUserCompleteRounds(false));
            return false; // Exclude the item from the filtered results
          }
        });

        const filterQuickPlayRoundByUser = data.filter((item) => {
          try {
            // Attempt to parse DatetimeStarted into a Date object
            const itemDate = new Date(item.DatetimeStarted);
            if (isNaN(itemDate.getTime())) {
              // console.error("❌ Invalid date for item:", item);
              dispatch(setIsUserCompleteRounds(false));
              return false; // Exclude the item from the filtered results
            }
            const itemDateString = itemDate.toISOString().split("T")[0];
            return (
              item.UserID === userData.uid &&
              item.GameType === "Quickplay" &&
              itemDateString === currentDateString
            );
          } catch (error) {
            // console.error("❌ Error parsing DatetimeStarted:", error);
            // console.error("❌ Invalid item:", item);
            dispatch(setIsUserCompleteRounds(false));
            return false; // Exclude the item from the filtered results
          }
        });
        const filterDailyPlayRoundByUser = data.filter((item) => {
          try {
            // Attempt to parse DatetimeStarted into a Date object
            const itemDate = new Date(item.DatetimeStarted);
            if (isNaN(itemDate.getTime())) {
              // console.error("❌ Invalid date for item:", item);
              dispatch(setIsUserCompleteRounds(false));
              return false; // Exclude the item from the filtered results
            }
            const itemDateString = itemDate.toISOString().split("T")[0];
            return (
              item.UserID === userData.uid &&
              item.GameType === "DailyChallenge" &&
              itemDateString === currentDateString
            );
          } catch (error) {
            // console.error("❌ Error parsing DatetimeStarted:", error);
            // console.error("❌ Invalid item:", item);
            dispatch(setIsUserCompleteRounds(false));
            return false; // Exclude the item from the filtered results
          }
        });
        const filterCustomGameRoundByUser = data.filter((item) => {
          try {
            // Attempt to parse DatetimeStarted into a Date object
            const itemDate = new Date(item.DatetimeStarted);
            if (isNaN(itemDate.getTime())) {
              // console.error("❌ Invalid date for item:", item);
              dispatch(setIsUserCompleteRounds(false));
              return false; // Exclude the item from the filtered results
            }
            const itemDateString = itemDate.toISOString().split("T")[0];
            return (
              item.UserID === userData.uid &&
              item.GameType === "CustomGame" &&
              itemDateString === currentDateString
            );
          } catch (error) {
            // console.error("❌ Error parsing DatetimeStarted:", error);
            // console.error("❌ Invalid item:", item);
            dispatch(setIsUserCompleteRounds(false));
            return false; // Exclude the item from the filtered results
          }
        });

        await dispatch(
          setCompleteRoundsData(filterRoundByUser ? filterRoundByUser : [])
        );
        await dispatch(
          setCompleteRoundsQuickPlayData(
            filterQuickPlayRoundByUser ? filterQuickPlayRoundByUser : []
          )
        );
        await dispatch(
          setCompleteRoundsDailyPlayData(
            filterDailyPlayRoundByUser ? filterDailyPlayRoundByUser : []
          )
        );
        await dispatch(
          setCompleteRoundsCustomGameData(
            filterCustomGameRoundByUser ? filterCustomGameRoundByUser : []
          )
        );
        await dispatch(setIsUserCompleteRounds(true));
      } catch (error) {
        // console.error("❌ Error fetching rounds data:", error);
        await dispatch(setIsUserCompleteRounds(false));
      }
    };

    if (userData?.uid && todayRounds?.Date) {
      fetchData(); // Call fetchData here
    }
  }, [dispatch, userData, todayRounds, winShow]);

  const today = new Date().toISOString().slice(0, 10);

  const dataLimit = 5;

  const getFinalData = async () => {
    try {
      dispatch(setLoading(true));
      const roundsRef = ref(db, "Results");
      const roundsSnapshot = await get(roundsRef);
      let result = await roundsSnapshot.val();

      const userRef = ref(db, "Users");
      const usersSnapshot = await get(userRef);
      let users = await usersSnapshot.val();

      if (result && Array.isArray(result) && users && Array.isArray(users)) {
        // today filters out

        const filteredData = result.filter(
          (item) => item.GameType === "DailyChallenge" && item.Result === 1
        );

        const outputData = filteredData.reduce((acc, curr) => {
          const existingItemIndex = acc.findIndex(
            (item) =>
              item.UserID === curr.UserID && item.GameType === curr.GameType
          );
          if (existingItemIndex !== -1) {
            acc[existingItemIndex].roundSum += curr.roundSum;
            acc[existingItemIndex].Result += curr.Result;
          } else {
            const user = users.find((user) => user.uid === curr.UserID);
            acc.push({
              UserID: curr.UserID,
              GameType: curr.GameType,
              roundSum: curr.roundSum,
              Result: curr.Result,
              userName: user ? user.userName : "Unknown",
            });
          }
          return acc;
        }, []);

        const filteredDataToday = result.filter((item) => {
          const itemDate = item.datestarted.slice(0, 10);
          return (
            item.GameType === "DailyChallenge" &&
            item.Result === 1 &&
            itemDate === today
          );
        });

        const outputTodayData = filteredDataToday.reduce((acc, curr) => {
          const existingItemIndex = acc.findIndex(
            (item) =>
              item.UserID === curr.UserID && item.GameType === curr.GameType
          );
          if (existingItemIndex !== -1) {
            acc[existingItemIndex].roundSum += curr.roundSum;
            acc[existingItemIndex].Result += curr.Result;
          } else {
            const user = users.find((user) => user.uid === curr.UserID);
            acc.push({
              UserID: curr.UserID,
              GameType: curr.GameType,
              roundSum: curr.roundSum,
              Result: curr.Result,
              userName: user ? user.userName : "Unknown",
            });
          }
          return acc;
        }, []);
        // Sort the outputData by roundSum in ascending order
        outputData.sort((a, b) => b.roundSum - a.roundSum);
        outputTodayData.sort((a, b) => b.roundSum - a.roundSum);

        // Select the first 5 items
        const shortestHeightData = outputData.slice(0, dataLimit);
        const shortestHeightTodayData = outputTodayData.slice(0, dataLimit);
        dispatch(setLeaderBoardData(shortestHeightData));
        dispatch(setDailyBoardData(shortestHeightTodayData));
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error fetching final data:", error);
    }
  };

  useEffect(() => {
    getFinalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/login"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          <Route
            path="/register"
            element={
              <Layout>
                <Register />
              </Layout>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Layout>
                <ForgotPassword />
              </Layout>
            }
          />
          <Route
            path="/confirm-password/:oobCode"
            element={
              <Layout>
                <ConfirmPassword />
              </Layout>
            }
          />
          <Route
            path="/select"
            element={
              <Layout flex={false}>
                <PlayScreen />
              </Layout>
            }
          />
          <Route
            path="/play/quick-play"
            element={
              <Layout>
                <QuickPlay />
              </Layout>
            }
          />
          <Route
            path="/play/daily-challenge"
            element={
              <Layout>
                <DailyChallenge />
              </Layout>
            }
          />
          <Route
            path="/play/custom-challenge"
            element={
              <Layout>
                <CustomPlay />
              </Layout>
            }
          />
          {/* <Route
            path="/play"
            element={
              <Layout>
                <Game />
              </Layout>
            }
          /> */}
        </Routes>
      </>
    </Router>
  );
};

export default MyRoutes;
