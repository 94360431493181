import { createSlice } from "@reduxjs/toolkit";
import { db } from "../../../config/firebase-config";
import { ref, get } from "firebase/database";

const initialState = {
  winPercentage: 0,
  played: 0,
  gradeDiff: {},
  loading: false,
  error: null,
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setWinPercentage: (state, action) => {
      state.winPercentage = action.payload;
    },
    setPlayed: (state, action) => {
      state.played = action.payload;
    },
    setGradeDiff: (state, action) => {
      state.gradeDiff = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setWinPercentage,
  setPlayed,
  setGradeDiff,
  setLoading,
  setError,
} = gameSlice.actions;

export const listenGamePlay =
  ({ userData }) =>
  async (dispatch) => {
    let gamesWon = 0;
    let totalGamesPlayed = 0;
    try {
      dispatch(setLoading(true));
      const getFinalData = async () => {
        try {
          const resultsRef = ref(db, "Results");
          const roundsSnapshot = await get(resultsRef);
          let roundsData = await roundsSnapshot.val();

          const roundsRef = ref(db, "Rounds");
          const snap = await get(roundsRef);
          const data = snap.val();

          if (roundsData && Array.isArray(roundsData)) {
            roundsData?.forEach((entry) => {
              // Check if the entry corresponds to a game won
              if (entry.UserID === userData.uid && entry.Result === 1) {
                gamesWon++;
              }
              // Count total games played for the user
              if (entry.UserID === userData.uid) {
                totalGamesPlayed++;
              }
            });
            const winPercentage = (gamesWon / totalGamesPlayed) * 100;
            const filteredData = data.filter((card) => {
              return card.UserID === userData.uid;
            });
            const gradeCounts = filteredData.reduce(
              (counts, item) => {
                if (item.GradeDiff === 0) {
                  counts["Dist0"]++;
                } else if (item.GradeDiff === 1) {
                  counts["Dist1"]++;
                } else if (item.GradeDiff === 2) {
                  counts["Dist2"]++;
                } else if (item.GradeDiff > 2) {
                  counts["Dist3"]++;
                }
                return counts;
              },
              { Dist0: 0, Dist1: 0, Dist2: 0, Dist3: 0 }
            );
            dispatch(setWinPercentage(winPercentage));
            dispatch(setPlayed(totalGamesPlayed));
            dispatch(setGradeDiff(gradeCounts));
          } else {
            dispatch(setWinPercentage(0));
            dispatch(setPlayed(0));
            dispatch(setGradeDiff({}));
            // setResultMatch(false);
            // Handle the case where result is null or not an array
          }
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setWinPercentage(0));
          dispatch(setPlayed(0));
          dispatch(setGradeDiff({}));
          dispatch(setLoading(false));
          console.error("Error fetching final data:", error);
        }
      };
      getFinalData();
    } catch (error) {
      dispatch(setError(error.message));
      dispatch(setLoading(false));
      dispatch(setLoading(false));
    }
  };

export default gameSlice.reducer;
