import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "react-avatar";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../config/firebase-config";
// import Home from "../../assets/image/home.png";
import {
  setUserData,
  setIsLoggedIn,
} from "../../state/features/user/userSlice";

import "./Header.scss";

const Header = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userData = useSelector((state) => state.user.userData);

  const dispatch = useDispatch();
  const history = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation();

  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(setIsLoggedIn(false));
        dispatch(setUserData({}));
        history("/");
      })
      .catch((error) => {
        // An error happened.
        console.log({ error });
      });
  };

  return (
    <div className="my-header">
      <div className="header-wrapper">
        {/* {pathname === "/" ? (
          <p></p>
        ) : ( */}
        <div className="header-icon">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => history("/")}
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg> */}
        </div>

        {/* <img alt="icon" src={Home} onClick={() => history("/")} /> */}

        {/* )} */}
        {isLoggedIn ? (
          <div className="user-avatar">
            {/* <p>
              {userData.displayName === null
                ? userData.email
                : userData.displayName}
            </p> */}
            <Avatar
              className="avatar-icon"
              size="42px"
              round
              src={userData.photoURL}
              name={
                userData.displayName === null
                  ? userData.email
                  : userData.displayName
              }
            />
            <div className="log-out" onClick={Logout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
